var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { customElement, html, LitElement, property, query } from 'lit-element';
import { PageMixin } from '../../client-packages/page.mixin';
import { store } from '../../redux/store';
import { RoomService } from '../../services/room.service';
import './add-room.scss';
let AddRoom = class AddRoom extends PageMixin(LitElement) {
    constructor() {
        super(...arguments);
        this.error = '';
    }
    render() {
        return html `
      <!-- Button trigger modal -->
      <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#createRoomModal">
        Neuen Raum erstellen
      </button>


      <div class="modal fade" id="createRoomModal" tabindex="-1" role="dialog" aria-labelledby="createRoomModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="createRoomModalLabel">Neuen Raum hinzufügen</h5>
              <button type="button" class="close btn" data-dismiss="modal" aria-label="Close" id="close-button">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form class="form">
                <div class="mb-3">
                  <label for="title">Raumname</label>
                  <input required type="text" class="form-control" id="title">
                </div>
                <div class="mb-3">
                  <label for="comfortTemp" class="form-label">Komforttemperatur</label>
                  <input id="comfortTemp" required class="form-control" type="number" value="21" step="0.5" min="0" max="40">  
                </div>
                <div class="mb-3">
                  <label for="emptyTemp" class="form-label">Absenktemperatur</label>
                  <input id="emptyTemp" required class="form-control" type="number" value="16" step="0.5" min="0" max="40">  
                </div>
                <div class="mb-3">
                  <label for="tempTime" class="form-label">Aufheiz-Zeit (in Minuten)</label>
                  <input id="tempTime" required class="form-control" type="number" value="45">  
                </div>
                <div class="mb-3">
                  <label for="fritzId" class="form-label">Fritzbox AIN</label>
                  <input id="fritzId" required class="form-control" type="text">  
                </div>
                <div class="mb-3">
                  <label for="color" class="form-label">Farbe im Kalender</label>
                  <input type="color" id="color" class="form-control form-control-color" name="color" value="">
                </div>
              </form>

              <div class="message-box">
                ${this.error !== '' ? html `
                <div  class="text-danger"> 
                  ${this.error}
                </div>
                ` : undefined}
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Abbrechen</button>
              <button type="button" class="btn btn-primary" @click="${this.submit}">Speichern</button>
            </div>
          </div>
        </div>
      </div>
      `;
    }
    async submit() {
        var _a;
        const user = store.getState().user;
        if (this.form.reportValidity()) {
            await RoomService.createRoom({
                title: this.titleInput.value,
                comfortTemp: Number(this.comfortTempInput.value),
                emptyTemp: Number(this.emptyTempInput.value),
                fritzId: this.fritzIdInput.value,
                createdFrom: user === null || user === void 0 ? void 0 : user.name,
                createdFromId: user === null || user === void 0 ? void 0 : user.id,
                eventColor: this.colorInput.value,
                tempTime: Number(this.tempTimeInput.value)
            });
            (_a = document.getElementById('close-button')) === null || _a === void 0 ? void 0 : _a.click();
        }
        else {
            this.error = 'Bitte füllen Sie alle Pflichtfelder aus.';
        }
    }
};
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Object)
], AddRoom.prototype, "error", void 0);
__decorate([
    query('form'),
    __metadata("design:type", HTMLFormElement)
], AddRoom.prototype, "form", void 0);
__decorate([
    query('#title'),
    __metadata("design:type", HTMLInputElement)
], AddRoom.prototype, "titleInput", void 0);
__decorate([
    query('#comfortTemp'),
    __metadata("design:type", HTMLInputElement)
], AddRoom.prototype, "comfortTempInput", void 0);
__decorate([
    query('#emptyTemp'),
    __metadata("design:type", HTMLInputElement)
], AddRoom.prototype, "emptyTempInput", void 0);
__decorate([
    query('#fritzId'),
    __metadata("design:type", HTMLInputElement)
], AddRoom.prototype, "fritzIdInput", void 0);
__decorate([
    query('#color'),
    __metadata("design:type", HTMLInputElement)
], AddRoom.prototype, "colorInput", void 0);
__decorate([
    query('#tempTime'),
    __metadata("design:type", HTMLInputElement)
], AddRoom.prototype, "tempTimeInput", void 0);
AddRoom = __decorate([
    customElement('add-room')
], AddRoom);
export default AddRoom;
