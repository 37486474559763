import { nanoid } from 'nanoid';
import { INITIAL_STATE } from '../store';
export const CLEAR_STORE = 'CLEAR_STORE';
export function clearStore() {
    return {
        type: CLEAR_STORE,
        id: nanoid(),
        state: { ...INITIAL_STATE }
    };
}
