export class Router {
    constructor() {
        this.listeners = [];
        this.rootPath = '/';
        this.routeHistory = [];
        window.onpopstate = () => this.notifyListeners();
        document.addEventListener('click', (event) => {
            if (!this.shouldIgnoreEvent(event)) {
                const anchor = this.getAnchor(event);
                if (anchor && !this.shouldIgnoreAnchor(anchor)) {
                    event.preventDefault();
                    this.navigate(anchor.pathname + anchor.search + anchor.hash);
                }
            }
        });
        if (document.getElementsByTagName('base').length > 0) {
            this.rootPath = document.getElementsByTagName('base')[0].getAttribute('href');
        }
        this.routeHistory.push(location.pathname + location.search);
    }
    subscribe(listener) {
        this.listeners.push(listener);
        return () => {
            this.listeners = this.listeners.filter(other => other !== listener);
        };
    }
    navigate(relUrl, pushHistory = true) {
        history.pushState(null, '', this.withRootPath(relUrl));
        if (pushHistory)
            this.routeHistory.push(relUrl);
        this.notifyListeners();
    }
    back() {
        const actualIndex = this.routeHistory.length - 1;
        if (this.routeHistory[actualIndex - 1]) {
            const backRoute = this.routeHistory[actualIndex - 1];
            this.routeHistory.pop();
            this.navigate(backRoute, false);
        }
    }
    getPath() {
        return this.withoutRootPath(location.pathname);
    }
    notifyListeners() {
        const path = this.getPath();
        this.listeners.forEach(listener => listener(path));
    }
    shouldIgnoreEvent(event) {
        return (event.defaultPrevented || event.button !== 0 || event.shiftKey || event.ctrlKey || event.altKey || event.metaKey);
    }
    getAnchor(event) {
        for (const target of event.composedPath ? event.composedPath() : []) {
            if (this.isAnchor(target)) {
                return target;
            }
        }
        let elem = event.target;
        while (elem && !this.isAnchor(elem)) {
            elem = elem.parentNode;
        }
        return elem && this.isAnchor(elem) ? elem : null;
    }
    isAnchor(elem) {
        return elem.nodeName && elem.nodeName.toLowerCase() === 'a';
    }
    shouldIgnoreAnchor(anchor) {
        if (anchor.target && anchor.target.toLowerCase() !== '_self') {
            return true;
        }
        if (anchor.hasAttribute('download')) {
            return true;
        }
        if (this.withRootPath(anchor.pathname) === window.location.pathname && anchor.hash !== '') {
            return true;
        }
        const origin = anchor.origin || this.getAnchorOrigin(anchor);
        if (origin !== window.location.origin) {
            return true;
        }
        return false;
    }
    getAnchorOrigin(anchor) {
        const port = anchor.port;
        const protocol = anchor.protocol;
        const defaultHttp = protocol === 'http:' && port === '80';
        const defaultHttps = protocol === 'https:' && port === '443';
        const host = defaultHttp || defaultHttps ? anchor.hostname : anchor.host;
        return `${protocol}//${host}`;
    }
    withRootPath(relURL) {
        if (relURL.startsWith(this.rootPath)) {
            return relURL;
        }
        else {
            return this.rootPath + (relURL.startsWith('/') ? relURL.substring(1) : relURL);
        }
    }
    withoutRootPath(relURL) {
        if (relURL.startsWith(this.rootPath)) {
            return relURL.substring(this.rootPath.length);
        }
        else {
            return relURL;
        }
    }
}
export const router = new Router();
