var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { customElement, html, LitElement, property } from 'lit-element';
import { PageMixin } from '../../client-packages/page.mixin';
import { router } from '../../client-packages/router';
import { userLogin } from '../../redux/actions/user.actions';
import { store } from '../../redux/store';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import './web-root.scss';
let WebRoot = class WebRoot extends PageMixin(LitElement) {
    constructor() {
        super(...arguments);
        this.isLogedIn = false;
        this.isAdmin = false;
        this.routes = new Map([
            ['login', { auth: false, admin: false, template: html `<web-login></web-login>` }],
            ['register', { auth: false, admin: false, template: html `<web-register></web-register>` }],
            ['events', { auth: true, admin: false, template: html `<web-events></web-events>` }],
            ['settings', { auth: true, admin: false, template: html `<web-settings></web-settings>` }],
            ['admin', { auth: true, admin: true, template: html `<web-admin></web-admin>` }],
        ]);
    }
    stateChanged(state) {
        var _a;
        this.isLogedIn = state.loggedIn;
        this.isAdmin = ((_a = state.user) === null || _a === void 0 ? void 0 : _a.role) === 'admin';
        if (!this.isLogedIn) {
            AuthService.onUserChange(async (firebaseUser) => {
                if (firebaseUser) {
                    const user = await UserService.getUser(firebaseUser.uid);
                    if (user) {
                        store.dispatch(userLogin({ ...user, id: firebaseUser.uid }));
                    }
                }
            });
        }
    }
    render() {
        return html `
        <web-navbar></web-navbar>
        <main class="content">
            ${this.renderOutlet()}
        </main>
        `;
    }
    firstUpdated() {
        router.subscribe(() => {
            this.requestUpdate();
        });
    }
    renderOutlet() {
        var _a, _b, _c, _d, _e, _f;
        const path = router.getPath();
        if ((((_a = this.routes.get(path)) === null || _a === void 0 ? void 0 : _a.auth) || path === '') && !this.isLogedIn) {
            router.navigate('login');
            return (_b = this.routes.get('login')) === null || _b === void 0 ? void 0 : _b.template;
        }
        else if ((path === 'login' || path === 'register' || path === '') && this.isLogedIn) {
            router.navigate('events');
            return (_c = this.routes.get('events')) === null || _c === void 0 ? void 0 : _c.template;
        }
        else {
            if ((((_d = this.routes.get(path)) === null || _d === void 0 ? void 0 : _d.admin) && this.isAdmin) || !((_e = this.routes.get(path)) === null || _e === void 0 ? void 0 : _e.admin)) {
                return (_f = this.routes.get(path)) === null || _f === void 0 ? void 0 : _f.template;
            }
        }
    }
};
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Object)
], WebRoot.prototype, "isLogedIn", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Object)
], WebRoot.prototype, "isAdmin", void 0);
WebRoot = __decorate([
    customElement('web-root')
], WebRoot);
export default WebRoot;
