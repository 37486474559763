import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from 'firebase/firestore';
import { firestore } from '../client-packages/firebase';
import { addRoom, deleteRoom, setRooms, updateRoom } from '../redux/actions/room.actions';
import { store } from '../redux/store';
export class RoomService {
    static async createRoom(room) {
        const roomsColl = collection(firestore, 'rooms');
        const newEvent = await addDoc(roomsColl, room);
        store.dispatch(addRoom({ ...room, id: newEvent.id }));
    }
    static async deleteRoom(roomId) {
        const roomRef = doc(firestore, 'rooms/' + roomId);
        try {
            await deleteDoc(roomRef);
            store.dispatch(deleteRoom(roomId));
        }
        catch (e) {
            throw new Error('Error by deleting Room! ' + e);
        }
    }
    static async updateRoom(room) {
        const roomRef = doc(firestore, 'rooms/', room.id);
        await updateDoc(roomRef, room);
        store.dispatch(updateRoom(room));
    }
    static async loadRooms() {
        const roomsRef = collection(firestore, 'rooms');
        const snapshot = await getDocs(roomsRef);
        const rooms = RoomService.getDataFromSnapshot(snapshot);
        store.dispatch(setRooms(rooms));
    }
    static getDataFromSnapshot(snapshot) {
        return snapshot.docs.map(value => {
            return { ...value.data(), id: value.id };
        });
    }
}
