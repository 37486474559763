var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Timestamp } from 'firebase/firestore';
import { customElement, html, LitElement, property, query } from 'lit-element';
import { PageMixin } from '../../client-packages/page.mixin';
import { EventService } from '../../services/event.service';
import './add-event.scss';
let AddEvent = class AddEvent extends PageMixin(LitElement) {
    constructor() {
        super(...arguments);
        this.rooms = [];
        this.user = undefined;
        this.seriesEvent = false;
        this.endlessEvent = false;
        this.duringHoliday = false;
        this.seriesEventDouble = false;
        this.allDay = false;
        this.error = '';
        this.loading = false;
    }
    stateChanged(state) {
        this.rooms = state.rooms.filter(r => !r.hidden);
        this.user = state.user;
    }
    updated(changedProperties) {
        if (changedProperties.has('seriesEvent')) {
            this.seriesDateInput.required = this.seriesEvent;
        }
    }
    render() {
        return html `
      <!-- Button trigger modal -->
      <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#createEventModal">
        Neuer Termin
      </button>


      <div class="modal fade" id="createEventModal" tabindex="-1" role="dialog" aria-labelledby="createEventModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="createEventModalLabel">Neuen Termin hinzufügen</h5>
              <button type="button" class="close btn" id="close-button" data-dismiss="modal" @click=${this.resetForm} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
            ${this.loading ? html `
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                ` : html `
              <form class="form">
                <div class="mb-3">
                  <label for="title">Titel ihrer Veranstaltung*</label>
                  <input required type="text" class="form-control" placeholder="Veranstaltungs Titel" id="title">
                </div>
                <div class="mb-3">
                  <label for="description">Beschreibung ihrer Veranstaltung</label>
                  <textarea class="form-control" aria-label="description" id="description" placeholder="Beschreibung"></textarea>
                </div>
                <div class="mb-3">
                  <label for="room">Raum für ihre Veranstaltung*</label>
                  <select required class="form-control" id="room">
                    ${this.rooms.map(room => html `<option value=${room.id}> ${room.title}</option>`)}
                  </select>
                </div>
                <div class="mb-3">
                  <label for="start-date" class="form-label">Start-Datum*</label>
                  <input id="start-date" required class="form-control" type="date" @input=${() => this.endDateInput.value = this.startDateInput.value}>  
                </div>
                ${!this.allDay ? html `
                <div class="mb-3">
                  <label for="start-time" class="form-label">Start-Uhrzeit*</label>
                  <input id="start-time" required class="form-control" type="time" @input=${() => this.endTimeInput.value = this.addHoursToTime(this.startTimeInput.value)}>  
                </div>
                ` : undefined}
                <div class="mb-3">
                  <label for="end-date" class="form-label">End-Datum*</label>
                  <input id="end-date" required class="form-control" type="date">  
                </div>
                ${!this.allDay ? html `
                <div class="mb-3">
                  <label for="end-time" class="form-label">End-Uhrzeit*</label>
                  <input id="end-time" required class="form-control" type="time">  
                </div>
                ` : undefined}
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value=${this.allDay} id="allDay" @input=${() => this.allDay = !this.allDay}>
                  <label class="form-check-label" for="allDay">
                    Ganztägiger Termin
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value=${this.seriesEvent} id="seriesEvent" @input=${() => this.seriesEvent = !this.seriesEvent}>
                  <label class="form-check-label" for="seriesEvent">
                    Serien Termin
                  </label>
                </div>
                ${this.seriesEvent ? html `
                <!-- Endless Event doesnt work. -->
                <!--   
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value=${this.endlessEvent} id="endlessEvent" @input=${() => this.endlessEvent = !this.endlessEvent}>
                    <label class="form-check-label" for="endlessEvent">
                      Endloser Termin
                    </label>
                  </div>
                  --> 
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value=${this.duringHoliday} id="duringHoliday" @input=${() => this.duringHoliday = !this.duringHoliday}>
                    <label class="form-check-label" for="duringHoliday">
                      Termin findet auch in den Ferien statt
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value=${this.seriesEventDouble} id="seriesEventDouble" @input=${() => this.seriesEventDouble = !this.seriesEventDouble}>
                    <label class="form-check-label" for="seriesEventDouble">
                      Termin findet 14-Tägig statt
                    </label>
                  </div>
                  ${!this.endlessEvent ? html `
                  <div class="mb-3">
                    <label for="seriesDate" class="form-label">Letzter Termin (Der Termin wird wöchentlich bis zu diesem Tag wiederholt)</label>
                    <input id="seriesDate" class="form-control" type="date" min="1">  
                  </div>
                  ` : undefined}
                ` : undefined}
              </form>

              `}
              

              <div class="message-box">
                ${this.error !== '' ? html `
                <div  class="text-danger"> 
                  ${this.error}
                </div>
                ` : undefined}
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal" @click=${this.resetForm}>Abbrechen</button>
              <button type="button" class="btn btn-primary" @click="${this.submit}">Speichern</button>
            </div>
          </div>
        </div>
      </div>
      `;
    }
    async submit(event) {
        var _a, _b, _c;
        event.preventDefault();
        if (this.form.reportValidity()) {
            this.loading = true;
            const seriesDate = this.seriesDateInput ? new Date(this.seriesDateInput.value) : undefined;
            const seriesEventDouble = this.seriesEventDouble;
            const room = this.rooms.find((r) => r.id === this.roomInput.value);
            const startDate = this.getDateFromInput(this.startDateInput.value);
            const endDate = this.getDateFromInput(this.endDateInput.value);
            let startMillis = 0;
            let endMillis = 0;
            if (this.allDay) {
                startMillis = new Date(Date.UTC(startDate[0], startDate[1] - 1, startDate[2])).getTime();
                endMillis = new Date(Date.UTC(endDate[0], endDate[1] - 1, endDate[2])).getTime();
            }
            else {
                const startTime = this.getTimeFromInput(this.startTimeInput.value);
                const endTime = this.getTimeFromInput(this.endTimeInput.value);
                startMillis = new Date(Date.UTC(startDate[0], startDate[1] - 1, startDate[2], startTime[0], startTime[1])).getTime();
                endMillis = new Date(Date.UTC(endDate[0], endDate[1] - 1, endDate[2], endTime[0], endTime[1])).getTime();
            }
            const startTimeStamp = Timestamp.fromMillis(startMillis);
            const endTimeStamp = Timestamp.fromMillis(endMillis);
            if (startTimeStamp <= endTimeStamp) {
                try {
                    const newEvent = {
                        title: this.titleInput.value,
                        description: this.descriptionInput.value,
                        start: startTimeStamp,
                        end: endTimeStamp,
                        room: room === null || room === void 0 ? void 0 : room.title,
                        roomId: room === null || room === void 0 ? void 0 : room.id,
                        createdFrom: (_a = this.user) === null || _a === void 0 ? void 0 : _a.name,
                        createdFromId: (_b = this.user) === null || _b === void 0 ? void 0 : _b.id,
                        createdAt: Timestamp.now(),
                        background: false,
                        allDay: this.allDay,
                        seriesEndless: this.endlessEvent,
                        seriesDuringHoliday: this.duringHoliday
                    };
                    await EventService.createEvent(newEvent, seriesDate, seriesEventDouble);
                    console.log(startDate);
                    console.log(seriesDate);
                    this.loading = false;
                    this.resetForm();
                    (_c = document.getElementById('close-button')) === null || _c === void 0 ? void 0 : _c.click();
                }
                catch (e) {
                    console.error(e);
                    this.error = 'Zur selben Zeit ist bereits der ausgewählte Raum ausgebucht.';
                    this.loading = false;
                }
            }
            else {
                this.loading = false;
                this.error = 'Der Startzeitpunkt ist nach dem Endzeitpunkt.';
            }
        }
        else {
            this.error = 'Bitte füllen Sie alle mit \'*\' markierten Felder aus.';
        }
    }
    resetForm() {
        this.endlessEvent = false;
        this.seriesEvent = false;
        this.duringHoliday = false;
        this.allDay = false;
        this.seriesEventDouble = false;
    }
    addHoursToTime(time) {
        const nextHour = Number(time.slice(0, 2)) + 1;
        return nextHour + ':' + time.slice(3, 5);
    }
    getTimeFromInput(time) {
        const arrTime = time.split(':');
        return arrTime.map(t => Number(t));
    }
    getDateFromInput(date) {
        const arrDate = date.split('-');
        return arrDate.map(d => Number(d));
    }
};
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Array)
], AddEvent.prototype, "rooms", void 0);
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Object)
], AddEvent.prototype, "user", void 0);
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Object)
], AddEvent.prototype, "seriesEvent", void 0);
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Object)
], AddEvent.prototype, "endlessEvent", void 0);
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Object)
], AddEvent.prototype, "duringHoliday", void 0);
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Object)
], AddEvent.prototype, "seriesEventDouble", void 0);
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Object)
], AddEvent.prototype, "allDay", void 0);
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Object)
], AddEvent.prototype, "error", void 0);
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Object)
], AddEvent.prototype, "loading", void 0);
__decorate([
    query('form'),
    __metadata("design:type", HTMLFormElement)
], AddEvent.prototype, "form", void 0);
__decorate([
    query('#title'),
    __metadata("design:type", HTMLInputElement)
], AddEvent.prototype, "titleInput", void 0);
__decorate([
    query('#description'),
    __metadata("design:type", HTMLInputElement)
], AddEvent.prototype, "descriptionInput", void 0);
__decorate([
    query('#room'),
    __metadata("design:type", HTMLInputElement)
], AddEvent.prototype, "roomInput", void 0);
__decorate([
    query('#start-date'),
    __metadata("design:type", HTMLInputElement)
], AddEvent.prototype, "startDateInput", void 0);
__decorate([
    query('#start-time'),
    __metadata("design:type", HTMLInputElement)
], AddEvent.prototype, "startTimeInput", void 0);
__decorate([
    query('#end-date'),
    __metadata("design:type", HTMLInputElement)
], AddEvent.prototype, "endDateInput", void 0);
__decorate([
    query('#end-time'),
    __metadata("design:type", HTMLInputElement)
], AddEvent.prototype, "endTimeInput", void 0);
__decorate([
    query('#seriesDate'),
    __metadata("design:type", HTMLInputElement)
], AddEvent.prototype, "seriesDateInput", void 0);
__decorate([
    query('#createEventModal'),
    __metadata("design:type", HTMLElement)
], AddEvent.prototype, "createEventModal", void 0);
AddEvent = __decorate([
    customElement('add-event')
], AddEvent);
export default AddEvent;
