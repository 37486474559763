import { nanoid } from 'nanoid';
export const USER_LOGIN = 'USER_LOGIN';
export const LOAD_USERS = 'LOAD_USERS';
export function userLogin(user) {
    return {
        id: nanoid(),
        type: USER_LOGIN,
        user: user
    };
}
export function setUsers(users) {
    return {
        id: nanoid(),
        type: LOAD_USERS,
        users
    };
}
