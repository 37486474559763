var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { customElement, html, LitElement, property, query } from 'lit-element';
import { PageMixin } from '../../client-packages/page.mixin';
import { router } from '../../client-packages/router';
import { AuthService } from '../../services/auth.service';
import * as configFile from '../../config.json';
import './web-login.scss';
const version = configFile.version;
const copy = configFile.copy;
let WebLogin = class WebLogin extends PageMixin(LitElement) {
    constructor() {
        super(...arguments);
        this.error = false;
    }
    render() {
        return html `
      <div class="signin-container">
        <form class="form-signin needs-validation">
          <h1 class="h3 mb-3 fw-normal">Bitte logge dich ein</h1>
  
          <div class="form-floating">
            <input type="email" class="form-control" id="email" placeholder="name@example.com">
            <label for="floatingInput">Email address</label>
          </div>
          <div class="form-floating">
            <input type="password" class="form-control" id="password" placeholder="Password">
            <label for="floatingPassword">Password</label>
          </div>
          
          <div class="message-box">
            ${this.error ? html `
            <div  class="text-danger"> 
              Login fehlgeschlagen! Haben Sie Email und Passwort richtig eingegeben? Ist ihr Account bereits aktiviert worden? Wenn nein, kontaktieren Sie einen Admin.
            </div>
            ` : undefined}
          </div>
  
          <button class="w-100 btn btn-lg btn-primary" type="submit" @click=${this.submit}>Login</button>
          
          <div class="form-text">Du hast noch keinen Account? <button type="button" class="btn btn-link" @click=${() => router.navigate('register')}>Registriere dich</button></div>
          <p class="mt-5 mb-3 text-muted">&copy;${copy} Jonathan Hartmann & \nTill Hoffmann v${version}</p>
        </form>
      </div>
        `;
    }
    async submit(event) {
        event.preventDefault();
        if (this.form.reportValidity()) {
            try {
                await AuthService.login(this.emailInput.value, this.passwordInput.value);
                this.form.reset();
                router.navigate('events');
            }
            catch (error) {
                console.error(error);
                this.error = true;
                this.requestUpdate();
            }
        }
    }
};
__decorate([
    query('form'),
    __metadata("design:type", HTMLFormElement)
], WebLogin.prototype, "form", void 0);
__decorate([
    query('#email'),
    __metadata("design:type", HTMLInputElement)
], WebLogin.prototype, "emailInput", void 0);
__decorate([
    query('#password'),
    __metadata("design:type", HTMLInputElement)
], WebLogin.prototype, "passwordInput", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Object)
], WebLogin.prototype, "error", void 0);
WebLogin = __decorate([
    customElement('web-login')
], WebLogin);
export default WebLogin;
