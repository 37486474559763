import { NoShadowMixin } from './noshadow.mixin';
import { connect } from 'pwa-helpers';
import { store } from '../redux/store';
export const PageMixin = (base) => {
    class PageMixin extends connect(store)(NoShadowMixin(base)) {
        createRenderRoot() {
            return this;
        }
    }
    return PageMixin;
};
