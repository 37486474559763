import { addDoc, collection, deleteDoc, doc, getDocs, Timestamp, updateDoc } from 'firebase/firestore';
import { nanoid } from 'nanoid';
import { firestore } from '../client-packages/firebase';
import { addEvent, deleteEvent, setEvents, updateEvent } from '../redux/actions/event.actions';
import { store } from '../redux/store';
const eventConverter = {
    toFirestore(event) {
        const returnEvent = {
            title: event.title,
            description: event.description,
            start: event.start,
            end: event.end,
            room: event.room,
            roomId: event.roomId,
            createdFrom: event.createdFrom,
            createdFromId: event.createdFromId,
            createdAt: event.createdAt,
            background: event.background,
            allDay: event.allDay,
            seriesEndless: event.seriesEndless,
            seriesDuringHoliday: event.seriesDuringHoliday
        };
        if (event.seriesId) {
            return {
                ...returnEvent,
                seriesId: event.seriesId,
                seriesNr: event.seriesNr
            };
        }
        return returnEvent;
    },
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        const returnEvent = {
            id: snapshot.id,
            title: data.title,
            description: data.description,
            start: data.start,
            end: data.end,
            room: data.room,
            roomId: data.roomId,
            createdFrom: data.createdFrom,
            createdFromId: data.createdFromId,
            createdAt: data.createdAt,
            background: data.background,
            allDay: data.allDay,
            seriesEndless: data.seriesEndless ? data.seriesEndless : false,
            seriesDuringHoliday: data.seriesDuringHoliday
        };
        if (data.seriesId) {
            return {
                ...returnEvent,
                seriesId: data.seriesId,
                seriesNr: data.seriesNr
            };
        }
        return returnEvent;
    }
};
export class EventService {
    static async createEvent(event, seriesDate = undefined, seriesEventDouble = undefined) {
        if (!seriesDate && !event.seriesEndless) {
            const validRoom = EventService.checkRoomValidity(event);
            if (validRoom) {
                await EventService.saveEvent(event);
            }
            else {
                throw new Error('Event is during a background event or an event has already been created in the same room and time');
            }
        }
        else if ((seriesEventDouble != true && seriesDate && seriesDate > new Date()) || event.seriesEndless) {
            const seriesId = nanoid();
            const oneYearFromNow = new Date(event.start.toDate());
            oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
            const seriesNr = event.seriesEndless ? EventService.getNrWeeksUntil(event.start.toDate(), oneYearFromNow) : EventService.getNrWeeksUntil(event.start.toDate(), seriesDate);
            let nextEvent = { ...event, seriesNr, seriesId };
            for (let i = seriesNr - 1; i >= 0; i--) {
                const validRoom = EventService.checkRoomValidity(event);
                const valid = event.seriesDuringHoliday ? true : EventService.checkValidity(nextEvent);
                if (valid && validRoom) {
                    EventService.saveEvent(nextEvent);
                }
                nextEvent = EventService.eventNextWeek(nextEvent, i, seriesId, seriesEventDouble);
            }
        }
        else if ((seriesEventDouble == true && seriesDate && seriesDate > new Date()) || event.seriesEndless) {
            const seriesId = nanoid();
            const oneYearFromNow = new Date(event.start.toDate());
            oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
            const seriesNr = event.seriesEndless ? EventService.getNrBiWeeksUntil(event.start.toDate(), oneYearFromNow) : EventService.getNrBiWeeksUntil(event.start.toDate(), seriesDate);
            let nextEvent = { ...event, seriesNr, seriesId };
            for (let i = seriesNr - 1; i >= 0; i--) {
                const validRoom = EventService.checkRoomValidity(event);
                const valid = event.seriesDuringHoliday ? true : EventService.checkValidity(nextEvent);
                if (valid && validRoom) {
                    EventService.saveEvent(nextEvent);
                }
                nextEvent = EventService.eventNextWeek(nextEvent, i, seriesId, seriesEventDouble);
            }
        }
    }
    static async updateEvent(event) {
        const valid = EventService.checkRoomValidity(event);
        if (valid) {
            await EventService.updateSingleEvent(event);
        }
        else {
            throw new Error('Event is during a background event or an event has already been created in the same room and time');
        }
    }
    static async deleteEvent(eventId, allSeries = false) {
        if (allSeries) {
            const firstEvent = store.getState().events.find(event => event.id === eventId);
            if (firstEvent && firstEvent.seriesId) {
                store.getState().events.forEach(async (event) => {
                    if (firstEvent.seriesId === event.seriesId && firstEvent.start.toDate() < event.start.toDate()) {
                        await EventService.removeEvent(event.id);
                    }
                });
            }
            await EventService.removeEvent(eventId);
        }
        else {
            await EventService.removeEvent(eventId);
        }
    }
    static async loadEvents() {
        const eventsRef = collection(firestore, 'events').withConverter(eventConverter);
        const snapshot = await getDocs(eventsRef);
        const events = await EventService.getDataFromSnapshot(snapshot);
        store.dispatch(setEvents(events));
    }
    static async createBackgroundEvent(title, start, end, user) {
        EventService.deleteBackroungEventsForTimespan(start, end);
        await EventService.createEvent({
            title: title,
            description: 'An diesem Tag können keine Buchungen vorgenommen werden.',
            start: Timestamp.fromDate(start),
            end: Timestamp.fromDate(end),
            room: '',
            roomId: '',
            createdFrom: user.name,
            createdFromId: user.id,
            createdAt: Timestamp.now(),
            background: true,
            seriesEndless: false,
            seriesDuringHoliday: false,
            allDay: true
        });
    }
    static checkValidity(event) {
        let validity = true;
        const backgroundEvents = store.getState().events.filter(e => e.background);
        backgroundEvents.forEach(backEvent => {
            if (event.start.toDate() >= backEvent.start.toDate() && event.start.toDate() <= backEvent.end.toDate() ||
                event.end.toDate() >= backEvent.start.toDate() && event.end.toDate() <= backEvent.end.toDate()) {
                validity = false;
            }
        });
        return validity;
    }
    static checkRoomValidity(event) {
        let validity = true;
        const sameRoomEvents = store.getState().events.filter(e => (e.roomId === event.roomId) && (e.id !== event.id));
        sameRoomEvents.forEach(roomEvent => {
            const roomEventEnd = roomEvent.end.toDate();
            roomEventEnd.setMinutes(roomEventEnd.getMinutes() - 1);
            const roomEventStart = roomEvent.start.toDate();
            roomEventStart.setMinutes(roomEventStart.getMinutes() + 1);
            if (event.start.toDate() >= roomEventStart && event.start.toDate() <= roomEventEnd ||
                event.end.toDate() >= roomEventStart && event.end.toDate() <= roomEventEnd) {
                validity = false;
            }
        });
        return validity;
    }
    static deleteBackroungEventsForTimespan(start, end) {
        const backgroundEvents = store.getState().events.filter(e => e.background);
        const inStart = start.getTime();
        const inEnd = end.getTime();
        backgroundEvents.forEach(async (backEvent) => {
            const backStart = backEvent.start.toMillis();
            const backEnd = backEvent.end.toMillis();
            if ((inStart <= backStart && inStart <= backEnd &&
                inEnd >= backStart && inEnd >= backEnd)
                ||
                    (inStart >= backStart && inStart <= backEnd &&
                        inEnd >= backStart && inEnd <= backEnd)) {
                console.log('Delete Event: ', backEvent.title);
                await EventService.deleteEvent(backEvent.id);
            }
        });
    }
    static getDataFromSnapshot(snapshot) {
        return snapshot.docs.map(value => {
            return {
                ...value.data(),
                id: value.id
            };
        });
    }
    static eventNextWeek(event, seriesNr, seriesId, seriesEventDouble = undefined) {
        if (seriesEventDouble == true) {
            seriesNr;
            const newEvent = {
                ...event,
                start: Timestamp.fromMillis(event.start.seconds * 1000 + 7 * 24 * 60 * 60 * 1000 * 2),
                end: Timestamp.fromMillis(event.end.seconds * 1000 + 7 * 24 * 60 * 60 * 1000 * 2),
                seriesId,
                seriesNr
            };
            return newEvent;
        }
        else {
            const newEvent = {
                ...event,
                start: Timestamp.fromMillis(event.start.seconds * 1000 + 7 * 24 * 60 * 60 * 1000),
                end: Timestamp.fromMillis(event.end.seconds * 1000 + 7 * 24 * 60 * 60 * 1000),
                seriesId,
                seriesNr
            };
            return newEvent;
        }
    }
    static async updateSingleEvent(event) {
        const eventRef = doc(firestore, 'events/', event.id).withConverter(eventConverter);
        await updateDoc(eventRef, event);
        store.dispatch(updateEvent(event));
    }
    static async saveEvent(event) {
        const eventsColl = collection(firestore, 'events').withConverter(eventConverter);
        const newEvent = await addDoc(eventsColl, { ...event });
        store.dispatch(addEvent({ ...event, id: newEvent.id }));
    }
    static async removeEvent(eventId) {
        const eventRef = doc(firestore, 'events/' + eventId);
        await deleteDoc(eventRef);
        store.dispatch(deleteEvent(eventId));
    }
    static getNrWeeksUntil(startDate, endDate) {
        return Math.round((endDate.getTime() - startDate.getTime()) / (7 * 24 * 60 * 60 * 1000)) + 1;
    }
    static getNrBiWeeksUntil(startDate, endDate) {
        return Math.round((endDate.getTime() - startDate.getTime()) / (7 * 24 * 60 * 60 * 1000 * 2)) + 1;
    }
}
