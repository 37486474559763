import { nanoid } from 'nanoid';
export const LOAD_EVENTS = 'LOAD_EVENTS';
export const ADD_EVENT = 'ADD_EVENT';
export const EDIT_EVENT = 'EDIT_EVENT';
export const DELTE_EVENT = 'DELTE_EVENT';
export function setEvents(events) {
    return {
        id: nanoid(),
        type: LOAD_EVENTS,
        events
    };
}
export function addEvent(event) {
    return {
        id: nanoid(),
        type: ADD_EVENT,
        event
    };
}
export function updateEvent(event) {
    return {
        id: nanoid(),
        type: EDIT_EVENT,
        event
    };
}
export function deleteEvent(eventId) {
    return {
        id: nanoid(),
        type: DELTE_EVENT,
        eventId
    };
}
