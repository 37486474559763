var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { customElement, html, LitElement, property, query } from 'lit-element';
import { PageMixin } from '../../client-packages/page.mixin';
import { store } from '../../redux/store';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import './web-settings.scss';
let WebSettings = class WebSettings extends PageMixin(LitElement) {
    constructor() {
        super();
        this.rooms = [];
        this.error = '';
        this.success = false;
        this.isLoading = false;
        this.user = store.getState().user;
    }
    render() {
        var _a, _b, _c;
        return html `
    <div class="container">
      <h1>Account Daten</h1>
      <form>
        <div class="mb-3">
          <label for="name" class="form-label">Name</label>
          <input type="email" class="form-control" id="name" aria-describedby="emailHelp" readonly .value=${(_a = this.user) === null || _a === void 0 ? void 0 : _a.name}>
        </div>
        <div class="mb-3">
          <label for="email" class="form-label">Email-Adresse</label>
          <input type="email" class="form-control" id="email" aria-describedby="emailHelp" readonly .value=${(_b = this.user) === null || _b === void 0 ? void 0 : _b.email}>
        </div>
        <div class="mb-3">
          <label for="role" class="form-label">Berechtigungen</label>
          <input type="text" class="form-control" id="role" .value=${(_c = this.user) === null || _c === void 0 ? void 0 : _c.role} readonly>
        </div>
      </form>


      <hr/>
      <h1>Passwort ändern</h1>
      <form class="w-50">
        <div class="mb-3">
          <label for="passwordOld" class="form-label">Altes Passwort</label>
          <input type="password" class="form-control" id="passwordOld" aria-describedby="password">
        </div>
        <div class="mb-3">
          <label for="password1" class="form-label">Neues Passwort</label>
          <input type="password" class="form-control" id="password1" aria-describedby="newPassword">
        </div>
        <div class="mb-3">
          <label for="password2" class="form-label">Neues Passwort wiederholen</label>
          <input type="password" class="form-control" id="password2">
        </div>

        <div class="message-box">
          ${this.error.length > 0 ? html `
          <div  class="text-danger"> 
            ${this.error}
          </div>
          ` : undefined}
        </div>

        <div class="message-box">
          ${this.success ? html `
          <div  class="text-success"> 
            Passwort wurde erfolgreich geändert!
          </div>
          ` : undefined}
        </div>
        
        <div class="message-box">
          ${this.isLoading ? html `
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          ` : undefined}
        </div>

        <button type="submit" class="btn btn-primary" @click=${this.updatePassword}>Speichern</button>
      </form>



      <hr/>
      <h1>Nutzer Account unwiderruflich löschen</h1>
      <button type="button" class="btn btn-danger" @click=${this.deleteUser}>Account löschen</button>
    </div>
    `;
    }
    async updatePassword(event) {
        event.preventDefault();
        if (this.form.reportValidity() && this.password1Input.value === this.password2Input.value && this.passwordOldInput.value) {
            try {
                this.isLoading = true;
                const success = await AuthService.updatePasswort(this.password1Input.value, this.passwordOldInput.value);
                if (success) {
                    this.error = '';
                    this.success = true;
                    setTimeout(() => this.success = false, 5000);
                }
                else {
                    this.error = 'Fehler beim Passwort ändern. Versuchen Sie es später noch einmal.';
                    console.error('Something went wrong by updating the password!');
                }
                this.isLoading = false;
                this.form.reset();
            }
            catch (error) {
                console.error(error);
                this.error = 'Fehler beim Passwort ändern. Versuchen Sie es später noch einmal.';
            }
        }
        else {
            this.error = 'Die Passwörter stimmen nicht überein oder das alte Passwort ist falsch!';
        }
    }
    deleteUser() {
        const deleteUser = confirm('Soll dieser Account wirklich unwiderruflich gelöscht werden?');
        if (this.user && deleteUser) {
            UserService.deleteUser(this.user.id);
        }
    }
};
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Object)
], WebSettings.prototype, "user", void 0);
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Array)
], WebSettings.prototype, "rooms", void 0);
__decorate([
    query('form'),
    __metadata("design:type", HTMLFormElement)
], WebSettings.prototype, "form", void 0);
__decorate([
    query('#passwordOld'),
    __metadata("design:type", HTMLInputElement)
], WebSettings.prototype, "passwordOldInput", void 0);
__decorate([
    query('#password1'),
    __metadata("design:type", HTMLInputElement)
], WebSettings.prototype, "password1Input", void 0);
__decorate([
    query('#password2'),
    __metadata("design:type", HTMLInputElement)
], WebSettings.prototype, "password2Input", void 0);
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Object)
], WebSettings.prototype, "error", void 0);
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Object)
], WebSettings.prototype, "success", void 0);
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Object)
], WebSettings.prototype, "isLoading", void 0);
WebSettings = __decorate([
    customElement('web-settings'),
    __metadata("design:paramtypes", [])
], WebSettings);
export default WebSettings;
