var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { customElement, html, LitElement, property, query } from 'lit-element';
import { styleMap } from 'lit-html/directives/style-map';
import { Modal, Tooltip } from 'bootstrap';
import { PageMixin } from '../../client-packages/page.mixin';
import { BASE_OPTION_REFINERS, Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import deLocale from '@fullcalendar/core/locales/de';
import adaptivePlugin from '@fullcalendar/adaptive';
import listPlugin from '@fullcalendar/list';
import './calendar.scss';
import { EventService } from '../../services/event.service';
import { store } from '../../redux/store';
import { getDate, getTime, getTimezoneTime, toDateTime } from '../../utils/utc-helper';
BASE_OPTION_REFINERS.schedulerLicenseKey = 'CC-Attribution-NonCommercial-NoDerivatives';
let WebCalendar = class WebCalendar extends PageMixin(LitElement) {
    constructor() {
        super();
        this.calendar = undefined;
        this.loading = false;
        this.user = undefined;
        this.rooms = new Map();
        this.events = [];
        this.selectedCalendarEvent = undefined;
        this.selectedEvent = undefined;
        this.detailsModal = undefined;
        this.selectedDate = new Date();
        this.showFilterTop = true;
        window.addEventListener('resize', () => {
            if (this.calendar) {
                this.calendar.render();
            }
        });
    }
    render() {
        return html `
        <div class="row">
          ${this.showFilterTop ? html `
            <div class="room-filter d-flex flex-column bd-highlight">
              <div class="action-section mb-3">
                <add-event></add-event>
              </div>
              <h4>Angezeigte Räume</h4>
              ${[...this.rooms.values()].map(roomObj => {
            const room = roomObj.room;
            return html `
              <div class="form-check form-check-inline user-select-none">
              <input class="form-check-input" type="checkbox" id=${'room-' + room.id} value=${room.id} ?checked=${roomObj.checked} @input=${() => this.roomFilter(room.id)} style=${styleMap({ borderColor: room.eventColor, backgroundColor: room.eventColor })}>
                <label class="form-check-label" for=${'room-' + room.id}>${room.title}</label>
              </div>`;
        })}
            </div>
          ` : html `
            <div class="room-filter d-flex flex-column bd-highlight">
              <div class="action-section mb-3">
                <add-event></add-event>
              </div>
            </div>
          `}

          <div class="calendar-section">
            ${this.loading ? html `
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ` : html `
              <div id="calendar"></div>
            `}
          </div>

          ${!this.showFilterTop ? html `
            <div class="room-filter d-flex flex-column bd-highlight">
              <h4>Angezeigte Räume</h4>
              ${[...this.rooms.values()].map(roomObj => {
            const room = roomObj.room;
            return html `
              <div class="form-check form-check-inline user-select-none">
                <input class="form-check-input" type="checkbox" id=${'room-' + room.id} value=${room.id} ?checked=${roomObj.checked} @input=${() => this.roomFilter(room.id)} style=${styleMap({ borderColor: room.eventColor, backgroundColor: room.eventColor })}>
                <label class="form-check-label" for=${'room-' + room.id}>${room.title}</label>
              </div>`;
        })}
            </div>
          ` : html ``}

        </div>

        <edit-event id="edit-event-modal" .event=${this.selectedEvent}></edit-event>
      `;
    }
    firstUpdated() {
        this.detailsModal = new Modal(document.getElementById('eventDetails'));
        this.user = store.getState().user;
        const modal = document.getElementById('eventDetails');
        window.onclick = (event) => {
            if (event.target == modal) {
                this.closeModal();
            }
        };
    }
    stateChanged(state) {
        this.loading = true;
        if (state.events.length >= 0) {
            this.events = state.events;
        }
        if (state.rooms.length > 0) {
            state.rooms.forEach(room => {
                if (!room.hidden) {
                    this.rooms.set(room.id, { room, checked: room.title !== 'Musikzimmer' });
                }
            });
            this.filterEvents();
            this.setResources();
            this.requestUpdate();
        }
        this.loading = false;
    }
    roomFilter(roomId) {
        const roomObj = this.rooms.get(roomId);
        if (roomObj) {
            this.rooms.set(roomId, { room: roomObj.room, checked: !roomObj.checked });
            this.setResources();
            this.filterEvents();
        }
    }
    filterEvents() {
        const filteredEvents = this.events.filter(event => { var _a; return event.background || ((_a = this.rooms.get(event.roomId)) === null || _a === void 0 ? void 0 : _a.checked); });
        this.renderCalendar(filteredEvents);
    }
    setResources() {
        var _a;
        const rooms = [...this.rooms.values()].filter(r => r.checked).map(r => r.room);
        (_a = this.calendar) === null || _a === void 0 ? void 0 : _a.getResources().forEach(r => r.remove());
        rooms.forEach(room => {
            var _a;
            (_a = this.calendar) === null || _a === void 0 ? void 0 : _a.addResource({ ...room });
        });
    }
    deleteEvent() {
        if (this.selectedCalendarEvent) {
            const deleteSingle = confirm('Soll diese Buchung wirklich gelöscht werden?');
            if (this.selectedCalendarEvent.extendedProps.seriesId) {
                const deleteFuture = confirm('Sollen zusätzlich alle Zukünftigen Buchungen gelöscht werden?');
                if (deleteFuture) {
                    EventService.deleteEvent(this.selectedCalendarEvent.id, true);
                    this.closeModal();
                    return;
                }
            }
            if (deleteSingle == true) {
                EventService.deleteEvent(this.selectedCalendarEvent.id);
                this.closeModal();
            }
        }
    }
    renderCalendar(events) {
        let formatEvents = [];
        if (events) {
            formatEvents = events.map(event => {
                var _a;
                let [startYear, startMonth, startDay] = getDate(toDateTime(event.start.seconds)).split('-').map(s => Number(s));
                const [endYear, endMonth, endDay] = getDate(toDateTime(event.end.seconds)).split('-').map(s => Number(s));
                const [startHours, startMinutes] = getTime(toDateTime(event.start.seconds)).split(':').map(s => Number(s));
                const [endHours, endMinutes] = getTime(toDateTime(event.end.seconds)).split(':').map(s => Number(s));
                const start = new Date(startYear, startMonth, startDay, startHours, startMinutes);
                const end = new Date(endYear, endMonth, endDay, endHours, endMinutes);
                const addEvent = {
                    title: event.title,
                    start: event.background || event.allDay ? new Date(startYear, startMonth, startDay) : start,
                    end: event.background || event.allDay ? new Date(endYear, endMonth, endDay) : end,
                    createdFrom: event.createdFrom,
                    resourceId: event.roomId,
                    display: event.background ? 'background' : undefined,
                    description: event.description,
                    room: event.room,
                    color: this.rooms.get(event.roomId) ? this.rooms.get(event.roomId).room.eventColor : '#b1b1b1',
                    createdFromId: event.createdFromId,
                    id: event.id,
                    seriesId: event.seriesId,
                    createdAt: (_a = event.createdAt) === null || _a === void 0 ? void 0 : _a.toDate()
                };
                if (event.allDay) {
                    return {
                        ...addEvent,
                        allDay: event.allDay,
                    };
                }
                else {
                    return addEvent;
                }
            });
        }
        let calendarConfig = {
            locales: [deLocale],
            locale: deLocale,
            eventDidMount: (info) => {
                info.el.setAttribute('data-bs-toggle', 'tooltip');
                info.el.setAttribute('data-bs-placement', 'bottom');
                let start = '';
                let end = '';
                let time = '';
                if (info.event.allDay) {
                    time = 'Ganztägiger Termin - ' + info.event.extendedProps.room;
                }
                else if (info.event.start && info.event.end) {
                    start = getTimezoneTime(info.event.start);
                    end = getTimezoneTime(info.event.end);
                    time = `${start}-${end}-${info.event.extendedProps.room}`;
                }
                info.el.title = info.event.display === 'background' ? 'Aufgrund der Ferien findet hier nichts statt.' : time;
                if (info.event.extendedProps.description) {
                    info.el.title += ' - ' + info.event.extendedProps.description;
                }
            },
            initialDate: this.selectedDate,
            weekNumbers: true,
            navLinks: true,
            editable: true,
            dayMaxEvents: 3,
            themeSystem: 'bootstrap',
            height: 'auto',
            eventClick: (info) => {
                this.openModal(info.event);
            }
        };
        if (window.innerWidth < 780) {
            calendarConfig.plugins = [listPlugin];
            calendarConfig.headerToolbar =
                {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'listWeek'
                };
            calendarConfig.initialView = 'listWeek';
            this.showFilterTop = false;
        }
        else {
            calendarConfig.plugins = [dayGridPlugin, timeGridPlugin, resourceTimeGridPlugin, adaptivePlugin];
            calendarConfig.headerToolbar =
                {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,resourceTimeGridDay'
                };
            calendarConfig.initialView = 'dayGridMonth';
        }
        if (formatEvents.length > 0) {
            calendarConfig = {
                ...calendarConfig,
                events: formatEvents
            };
        }
        if (this.calendarElement && calendarConfig) {
            this.calendar = new Calendar(this.calendarElement, calendarConfig);
            this.calendar.render();
            this.calendar.updateSize();
            const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
            tooltipTriggerList.map(function (tooltipTriggerEl) {
                return new Tooltip(tooltipTriggerEl);
            });
            const setSelectedDate = () => {
                this.selectedDate = this.calendar ? this.calendar.getDate() : this.selectedDate;
            };
            document.getElementsByClassName('fc-next-button')[0].addEventListener('click', setSelectedDate);
            document.getElementsByClassName('fc-prev-button')[0].addEventListener('click', setSelectedDate);
        }
    }
    openModal(event) {
        this.selectedCalendarEvent = event;
        this.selectedEvent = this.events.find(e => e.id === event.id);
        const modal = document.getElementById('edit-event-modal');
        if (modal) {
            modal.openModal(this.selectedEvent);
        }
    }
    closeModal() {
        var _a;
        (_a = this.detailsModal) === null || _a === void 0 ? void 0 : _a.toggle();
    }
};
__decorate([
    query('#calendar'),
    __metadata("design:type", HTMLElement)
], WebCalendar.prototype, "calendarElement", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Object)
], WebCalendar.prototype, "loading", void 0);
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Object)
], WebCalendar.prototype, "user", void 0);
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Map)
], WebCalendar.prototype, "rooms", void 0);
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Array)
], WebCalendar.prototype, "events", void 0);
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Object)
], WebCalendar.prototype, "selectedCalendarEvent", void 0);
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Object)
], WebCalendar.prototype, "selectedEvent", void 0);
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Object)
], WebCalendar.prototype, "detailsModal", void 0);
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Date)
], WebCalendar.prototype, "selectedDate", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Object)
], WebCalendar.prototype, "showFilterTop", void 0);
WebCalendar = __decorate([
    customElement('web-calendar'),
    __metadata("design:paramtypes", [])
], WebCalendar);
export default WebCalendar;
