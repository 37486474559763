import { CLEAR_STORE } from './actions/clear.actions';
import { ADD_EVENT, DELTE_EVENT, LOAD_EVENTS, EDIT_EVENT } from './actions/event.actions';
import { ADD_ROOM, DELETE_ROOM, EDIT_ROOM, LOAD_ROOMS } from './actions/room.actions';
import { LOAD_USERS, USER_LOGIN } from './actions/user.actions';
import { INITIAL_STATE } from './store';
export const reducer = (state = { ...INITIAL_STATE }, action) => {
    switch (action.type) {
        case USER_LOGIN:
            state.user = action.user;
            state.loggedIn = true;
            return state;
        case LOAD_EVENTS:
            state.events = action.events;
            return state;
        case ADD_EVENT:
            state.events = [...state.events, action.event];
            return state;
        case EDIT_EVENT:
            state.events = state.events.map(event => event.id === action.event.id ? action.event : event);
            return state;
        case DELTE_EVENT:
            state.events = state.events.filter(e => e.id !== action.eventId);
            return state;
        case LOAD_ROOMS:
            state.rooms = action.rooms;
            return state;
        case ADD_ROOM:
            state.rooms = [...state.rooms, action.room];
            return state;
        case EDIT_ROOM:
            state.rooms = state.rooms.map(room => room.id === action.room.id ? action.room : room);
            return state;
        case DELETE_ROOM:
            state.rooms = state.rooms.filter(r => r.id !== action.roomId);
            return state;
        case LOAD_USERS:
            state.users = action.users;
            return state;
        case CLEAR_STORE:
            state = { ...INITIAL_STATE };
            return state;
        default:
            return state;
    }
};
