var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { customElement, html, LitElement, property } from 'lit-element';
import { PageMixin } from '../../client-packages/page.mixin';
import { router } from '../../client-packages/router';
import { AuthService } from '../../services/auth.service';
import './navbar.scss';
let WebNavbar = class WebNavbar extends PageMixin(LitElement) {
    constructor() {
        super();
        this.path = '';
        this.smallScreen = true;
        this.isLogedIn = false;
        this.isAdmin = false;
        this.dropdown = undefined;
        this.path = router.getPath();
        router.subscribe((path) => this.path = path);
        this.smallScreen = window.innerWidth < 992;
        window.addEventListener('resize', () => {
            this.smallScreen = window.innerWidth < 992;
        });
    }
    stateChanged(state) {
        var _a;
        this.isLogedIn = state.loggedIn;
        this.isAdmin = ((_a = state.user) === null || _a === void 0 ? void 0 : _a.role) === 'admin';
    }
    render() {
        return html `
      <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container-fluid">
          <span class="navbar-brand mb-0 h1">Raum Buchung</span>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse flex-row-reverse" id="navbarSupportedContent">
            <ul class="navbar-nav">
            ${this.isLogedIn && this.isAdmin ? html `
                <li class="nav-item">
                  <a class="nav-link" href="/admin">Admin</a>
                </li>
                ` : undefined}
              ${this.path === 'login' ? html `
                <li class="nav-item">
                  <a class="nav-link" href="/register">Registrieren</a>
                </li>
                ` : undefined}
              ${this.path === 'register' ? html `
                <li class="nav-item">
                  <a class="nav-link" href="/login">Login</a>
                </li>
                ` : undefined}
              ${this.isLogedIn ? html `
                <li class="nav-item">
                  <a class="nav-link" href="/events">Buchung</a>
                </li>

                ${this.smallScreen ? html `
                  <li class="nav-item">
                    <a class="nav-link" href="/settings">Einstellungen</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#" @click=${this.logout}>Logout</a>
                  </li>
                  ` : html `
                  <li class="nav-item dropstart dropdown">
                    <a class="nav-link" href="#" @click=${this.logout}>
                      Logout
                    </a>
                  </li>
                  <li class="nav-item dropstart dropdown">
                    <a class="nav-link nav-icon" href="/settings">
                      <i class="bi bi-person-circle"></i>
                    </a>
                  </li>
                  `}
                ` : undefined}
            </ul>
          </div>
        </div>
      </nav>
      `;
    }
    async logout() {
        const successful = await AuthService.logout();
        if (successful) {
            router.navigate('login');
        }
        else {
            alert('Es gab einen Fehler beim ausloggen. Probiere es gleich nochmal!');
        }
    }
};
__decorate([
    property(),
    __metadata("design:type", Object)
], WebNavbar.prototype, "path", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Object)
], WebNavbar.prototype, "smallScreen", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Object)
], WebNavbar.prototype, "isLogedIn", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Object)
], WebNavbar.prototype, "isAdmin", void 0);
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Object)
], WebNavbar.prototype, "dropdown", void 0);
WebNavbar = __decorate([
    customElement('web-navbar'),
    __metadata("design:paramtypes", [])
], WebNavbar);
export default WebNavbar;
