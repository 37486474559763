import { nanoid } from 'nanoid';
export const LOAD_ROOMS = 'LOAD_ROOMS';
export const ADD_ROOM = 'ADD_ROOM';
export const DELETE_ROOM = 'DELETE_ROOM';
export const EDIT_ROOM = 'EDIT_ROOM';
export function setRooms(rooms) {
    return {
        id: nanoid(),
        type: LOAD_ROOMS,
        rooms
    };
}
export function addRoom(room) {
    return {
        id: nanoid(),
        type: ADD_ROOM,
        room
    };
}
export function updateRoom(room) {
    return {
        id: nanoid(),
        type: EDIT_ROOM,
        room
    };
}
export function deleteRoom(roomId) {
    return {
        id: nanoid(),
        type: DELETE_ROOM,
        roomId
    };
}
